export const navLinks = [
  {
    path: "/",
    name: "Ensino",
  },
  {
    path: "/",
    name: "Pesquisa",
  },
  {
    path: "/",
    name: "Inovação",
  },
  {
    path: "/",
    name: "Cerca da OTS",
  },
];
