export const topics = [
  {
    slug: "/institucional",
    title: "Institucional",
    description: "Lorem ipsum dolor sit amet",
    subtopics: [
      {
        slug: "/manual-de-fundação",
        title: "Manual de Fundação",
        description: "Lorem ipsum dolor sit amet",
      },
      {
        slug: "/perguntas-frequentes",
        title: "Perguntas Frequentes",
        description: "Lorem ipsum dolor sit amet",
      },
    ],
  },
  {
    slug: "/documentacao",
    title: "Documentação",
    description: "Lorem ipsum dolor sit amet",
  },
];
